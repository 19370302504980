@import 'colors';
footer {
    font-family: 'Futura';
    .footer-links {
        padding: 12px 0;
        color: #efefef;
        background-color: $blue;
        .f-links {
            display: flex;
            flex-direction: column;
            .links {
                display:flex;
                flex-wrap: wrap;
                margin-bottom: 12px;
            }
            a {
                width:100%;
                overflow: hidden;
                @media (max-width: 767px){
                    width:50%;
                }
            }
        }

        h2 {
            font-size: 24px;
            font-family: 'Futura-Medium';
            margin: 0 0 16px 0;
        }

        a {
            color:inherit;
            text-decoration: none;
            font-size: 18px;
            font-family: 'Futura';
            display:block;
            margin:6px 0;
        }
    }
    .footer-company {
        padding: 12px 0;
        color:rgb(103, 105, 107);
        background-color: lighten($accent,14%);
        h3  {
            text-align: center;
            font-size: 1.75rem;
            font-family: 'Futura';
            color:rgb(30, 31, 31);
        }
        .copyright {
            text-align: center;
            font-size: 14px;
            margin:0;
        }
        .social-links {
            display:flex;
            align-items: center;
            justify-content: center;
            a {
                color:inherit;
                opacity: 0.7;
                display:block;
                padding:4px;
                margin:4px;
                svg {
                    width: 18px;
                    height: 18px;
                }
                .icon-lg {

                }
            }
        }
    }
}