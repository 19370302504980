@import 'colors';

.header-container {
    &.top-bar {
        height: 36px;
        display: flex;
        align-items: center;
        color: #fff;
        text-align: center;
        font-size: 20px;
        justify-content: center;
        align-items: center;
        position: relative;

        h4 {
            margin: 0;

            @media screen and (max-width: 500px) {
                font-size: 14px !important;
            }

            @media screen and (max-width: 400px) {
                font-size: 12px !important;
            }
        }

        .user {
            position: absolute;
            top: 50%;
            right: 0;
            width: auto;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            height: 36px;
            font-size: 14px;
            font-family: 'Futura-Medium';

            .profile {
                display: flex;
                align-items: center;
                height: 36px;

                img.icon {
                    width: 20px;
                    height: 60px;
                    padding: 0;

                    object-fit: contain;
                    background-repeat: no-repeat;
                }

                img {
                    width: 30px;
                    height: 30px;
                    object-fit: cover;
                    border-radius: 50%;
                    display: block;
                    margin-right: 10px;
                }
            }

            span {
                margin: 0 10px;
                display: block;
            }

            .icon {
                margin: 0 5px;
                display: block;
                width: 24px;
                height: 24px;
            }

            a {
                text-decoration: none;
                color: inherit;
            }

            p {
                margin: 0;
                cursor: pointer;
            }
        }

        @media (max-width: 580px) {
            height: 48px;
        }
    }

    &.header {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        width: 100%;
        z-index: 100;
        background: #fff;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        transition: all .5s ease-in-out;

        @media (max-width: 1332px) {
            margin-bottom: 60px;
            /*  position: relative; */
        }
    }

    .links {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 550px;
        flex-grow: 1;

        a {
            color: $primary;
            font-size: 18px;
            text-decoration: none;
            display: block;
            font-family: Futura-Medium;
            padding: 0 8px;
            text-transform: capitalize;
        }
    }

    .nav {
        align-items: center;
        justify-content: space-between;
        height: 96px;

        &>* {
            width: auto;
            padding: 0;
        }

        .menu-icon {
            color: $primary;
            width: 32px;
            height: 32px;
            cursor: pointer;
        }

        .logo {
            width: 207px;
            height: 69px;
        }

        .search-field {
            width: 275px;

            @media (max-width: 1332px) {
                position: absolute;
                bottom: -48px;
                width: calc(100% - 24px);
            }
        }

        .cart-icon {
            position: relative;
            cursor: pointer;

            >img {
                width: 20px;
                height: 26px;
                color: #46494b;
                object-fit: contain;
            }

            .count {
                background: $primary;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                color: #fff;
                font-size: 12px;
                font-weight: 100;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: -10px;
                right: -6px;
                pointer-events: none;
            }
        }
    }

    .mob-search-field {
        padding: 10px 0;
        height: 58px;
    }

    .nav-search {
        position: relative;

        svg {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-50%, -50%);
            cursor: pointer;
            color: #797979;
        }
    }

}

.drawer-body {
    min-height: 100vh;
    background: $primary;
    position: relative;
    width: calc(100vw - 60px);

    .drawer-bar {
        background: $accent;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;

        img {
            height: 32px;
            width: 32px;
            border-radius: 50%;
        }

        label {
            font-size: 20px;
            display: block;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 60%;
            position: relative;
            color: #333;
            font-family: MuseoSans-500;
        }

        .icon {
            height: 32px;
            width: 32px;
            color: white;
        }

    }

    .drawer-nav {
        list-style: none;
        list-style-position: inside;
        margin: 0;
        padding: 0;

        li {
            width: auto;
            padding: 10px 24px;

            a {
                display: inline-block;
                padding: 0;
                text-transform: uppercase;
                color: #fff;
                text-decoration: none;
                font-size: 18px;
                font-family: 'Futura';
                font-weight: 400;
                position: relative;

                &:after {
                    content: '';
                    border-bottom: 2px solid #fff;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0%;
                    transition: all 0.2s ease-in;
                }

                &:hover {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }

    .close-btn {
        width: 48px;
        height: 48px;
        color: $accent;
        cursor: pointer;
        padding: 10px;
        position: absolute;
        left: -48px;
        top: 0;
        z-index: 999;
    }
}

.cart-drawer-body {
    height: 100vh;
    background: #fff;
    position: relative;
    padding: 0 12px;
    margin-bottom: 12px;
    width: 100vw;
    max-width: 800px;

    .drawer-bar {
        background: #fff;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 12px 0;
        border-bottom: 1px solid #d4d4d4;

        label {
            font-size: 20px;
            display: block;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 60%;
            position: relative;
            color: #333;
            font-family: MuseoSans-500;
        }

        .close-btn {
            width: 48px;
            height: 48px;
            color: #222;
            cursor: pointer;
            padding: 10px;
        }
    }

    .drawer-footer {
        height: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin: 0 -15px;
        margin-top: 30px;

        .btn {
            margin: 0 12px;
            border-radius: 28px;
            flex-grow: 1;
        }
    }

}

.suggest {
    height: calc(100% - 80px);
    overflow: auto;
    max-width: 600px;
    margin: 15px auto 0;
}

.MuiDrawer-paper {
    /* width: calc(100% - 60px); */
    overflow: visible;
    overflow-y: visible !important;
}