@import 'colors';
.listing {
    

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 12px;
        padding-bottom: 12px;
        h2 {
            font-family: Galada,cursive;
            font-size: 48px;
            margin:0;
            margin-bottom: -16px;
        }

        button {
            margin-left: 6px;
            border: 0;
            border-radius: 5px;
            padding: 3px 10px;
            color: #fff;
            font-family: 'Futura-Medium';
            background-color: $dark;
            opacity: 0.5;
            transition: all 0.25 ease-in-out;
            &.active {
                opacity: 1;
                background-color: $primary;
            }
        }
    }

    .desc {
        font-size: 18px;
        a {
            color: $blue;
            text-decoration: none;
            font-weight: 600;
        }
    }
    .desc1 {
        font-size: 18px;
        background-color: #014a89;
        color: #fff;
        padding: 10px;
        a {
            color: $blue;
            text-decoration: none;
            font-weight: 600;
        }
    }

    

    #cat-map {
        width: 100%;
        min-height: 60vh;
        margin-bottom: 24px;
    }
    #list-map {
        width: 100%;
        min-height: 60vh;
        margin-bottom: 24px;
    }


    .filter {
        h3 {
            /* font-family: 'Futura'; */
            font-weight: 100;
            font-size: 20px;
            padding-bottom: 12px;
            padding-top: 20px;
            border-bottom: 1px solid #e8e8e8;
            height:54px;
        }

        .accordion-item {
            border-color: transparent !important;
            button {
                padding: 6px 0;
                font-family: 'Futura';
            }
            .accordion-button:not(.collapsed) {
                color: #222;
                background-color: #fff;
                box-shadow: inset 0 -1px 0 rgba(0 ,0 ,0 , 0.13);
            }

            .accordion-body {
                padding:6px;
                ul {
                    padding:0 0 0 10px;
                    list-style-type: none;
                    li {
                        padding:5px 0;
                    }
                }
            }
        }
    }

    .react-date-picker__wrapper {
        border:0;
    }

    .accordion-button::after {
        $size:0.725rem;

        width: $size;
        height: $size;
        background-size: $size;
    }
    .no-product {
        font-size: 18px;
        color:$danger;
        font-family: 'Futura-Bold';
        text-align: center;
    }
}
.vendor {
    text-align: center;
    margin-bottom: 25px;
    .profile-img {
        position: relative;
        
        img {
            width:100%;
        }

        .loved {
            position:absolute;
            bottom: 10px;
            right: 10px;
            border-radius: 50%;
            width:30px;
            height: 30px;
            background: #ffffff;
            display:flex;
            cursor: pointer;

            svg{
                pointer-events: none;
                width:30px;
                height:30px;
                padding: 7px 6px 5px 6px;
                color: $primary;
            }
        }
    }

    h3 {
        text-align: center;
        margin:10px 0;
        font-size: 16px;
        font-family: 'Futura-book';
        font-weight: 600;
        
    }
    .lang {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin:0;
        width:100%;
        overflow:hidden;
        text-align: center;
    }
    .address {
      
        margin:0;
        width:100%;
       
        text-align: center;
    }
    .social-icon {
        display: flex;
        align-items: center;
        justify-content:center;
        margin:12px 0;
        .icon {
            width:24px;
            height:24px;
            margin:6px;
            color:rgb(90, 90, 90);
            &:hover {
                color:$primary;
            }
        }
    }
}

.shop-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 12px 0;

    button {
        border: 1px solid #ced4da !important;
        border-radius: 5px !important;
        background: white;
        color:rgb(109, 109, 109);
        font-size: 17px;
        line-height: 17px;
        font-family: 'Futura-Medium';
        padding: 7px 20px !important;
        margin-left: 12px;
        &.active {
            background: $primary;
            color:white;
            border: 1px solid $primary !important;
        }
    }
}
section.listing .item {
    background: #fff;
    margin-bottom: 15px;
    padding-bottom: 5px;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    min-height: 400px;
}
section.listing .item .thumb img {
    background-size: cover !important;
}