@import 'colors';
.mobile-filter {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .btns {
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 49%;
        }
    }
    
    .menu-icon {
        width: 38px;
        height: 38px;
        background-color: $primary;
        padding: 4px;
        color:white;
        margin-right: 10px;
        border-radius: 3px;
    }

    .dropdown {
        flex-grow: 1;
        button {
            width: 100%;
        }
    }

    .sort-icon {
        width:24px;
        height:24px;

    }
}

.filter-drawer {
    .drawer-bar {
        background:none;
        border-bottom: 1px solid #fff;
        margin:0 15px;
        padding:0;
        color:#fff;
        font-size: 20px;
        font-family: 'Futura-Medium';
    }

    .filter {
        margin:15px;
        color:#fff;
        overflow: auto;
        height: calc(100vh  - 85px);
        .accordion-item {
            background-color:transparent;
            border: 0px solid rgba(0, 0, 0, 0.125);
            .accordion-header {
                border-bottom: 1px solid #fff;
            }
            .accordion-button {
                background: transparent !important;
                color:#fff;
            }

            .accordion-body {
                ul {
                    list-style-type: none;
                    list-style-position: inside;
                    margin:0;
                    padding:0;
                }
            }
        }

        &.sort {
            ul {
                list-style-type: none;
                padding:0;
                margin:0;
            }
        }
    }
}



