@import 'colors';
.address-card {
    background-color: lighten($accent,14%);
    padding: 10px;
    border:2px dashed lighten($accent,7%);
    font-family: Futura-Book;
    margin-bottom: 15px;
    border-radius: 5px;
    cursor: pointer;

    .top {
        display:flex;
        align-items:center;
        justify-content: space-between;

        span {
            background:$primary;
            color: #f4f4f4;
            padding:2px 4px;
            font-size: 14px;
            border-radius: 3px;
        }
    }

    h2 {
        font-size: 18px;
        margin:0;
    }

    hr {
        margin:5px 0;
    }

    p {
        margin: 0;
        padding:0;
        display: flex;
        align-items: center;
        >svg {
            margin-right: 6px;
            color:rgb(109, 109, 109);
        }
        
        &.name {
            font-size: 18px;
        }
        &.mobile {
            font-size: 16px;
            >svg {
                width: 18px;
                height: 18px;
            }
        }
        &.address {
            align-items: start;
            line-height: 18px;
        }
    }
}
.addresses-container {
    max-height: 50vh;
    overflow: auto;
}