@import 'colors';

.cart-items {
    margin: 29px 0;
    height: calc(100vh - 160px);
    overflow: hidden;
    overflow-y: auto;

    .cart-item {
        display: flex;
        /* align-items: center; */
        border-bottom: 1px solid #d4d4d4;
        padding: 12px 0;

        .pic {
            padding-top: 15px;

            img {
                width: 100%;

            }
        }

        .cart-info {
            padding: 12px 0 12px 12px;

            p,
            h2 {
                color: #696969;
                margin-bottom: 3px;
                font-size: 16px;
                font-family: Futura;

                .price {
                    font-size: inherit;
                    font-family: inherit;
                }
            }

            h2 {
                font-size: 18px;
            }
        }


        .more-details {

            display: none;

            &.show {
                display: block;
            }
        }

        .more {
            font-weight: 700;
            color: #222 !important;
            cursor: pointer;
        }

        .couponApply {
            border: 2px solid #9CCC65;
            border-radius: 8px;
            width: 250px;
            padding: 10px;

            p {
                font-size: 14px;
                font-weight: bold;
            }

            span {
                font-size: 12px;
                color: #a9a7a7fc;
                font-weight: bold;
            }

            .removebtn {
                float: right;
                text-decoration: none;
                cursor: pointer;
                margin-top: -10px;
                font-size: 14px;
                font-weight: bold;
            }
        }

        .coupon {
            border-radius: 5px;
            border: 1px solid #d4d4d4;
            overflow: hidden;
            display: flex;
            max-width: 320px;
            margin-bottom: 8px;

            input {
                border: 0;
                height: 32px;
                flex-grow: 1;
                padding: 0 10px;
                font-family: Futura-Bold;
                letter-spacing: 0.3px;
            }

            button {
                border: 0;
                height: 32px;
                color: #fff;
                background-color: $blue;
                text-align: center;
                width: 108px;
                flex-shrink: 0;
                padding: 0
            }
        }

        .actions {
            display: flex;
            font-weight: 700;
            margin: 6px;
            color: #222 !important;

            a {
                text-decoration: none;
                color: #222;
                margin: 0 10px;
            }

            p {
                margin: 0 10px;
                cursor: pointer;
                color: #222 !important;
            }
        }

        &.small {
            align-items: flex-start;

            .cart-info {
                >p {
                    display: none;
                }
            }

            .coupon {
                display: none;
            }

            .actions {
                display: none;
            }

            .form-check {
                display: none;
            }
        }

        .gift-note {
            max-width: 300px;

            .note {
                border-radius: 5px;
                border: 1px solid #d4d4d4;
                overflow: hidden;
                display: flex;
                max-width: 320px;
                margin-bottom: 8px;

                input {
                    border: 0;
                    height: 32px;
                    flex-grow: 1;
                    padding: 0 10px;
                    font-family: Futura;
                    letter-spacing: 0.3px;
                }

                button {
                    border: 0;
                    height: 32px;
                    color: #fff;
                    background-color: $blue;
                    text-align: center;
                    width: 68px;
                    flex-shrink: 0;
                    padding: 0
                }
            }
        }

        .pqa {
            margin-top: 12px;

            .title {
                font-weight: 500;
                font-size: 18px;

            }

            .pq {
                padding-left: 12px;
                font-weight: 500;
                color: #222;
                font-family: Futura-Medium;
            }

            .pa {
                padding-left: 12px;
                margin-bottom: 15px
            }
        }

    }

}

.empty-cart {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
        font-family: Museosans-700;
        font-size: 18px;
    }

    p {
        font-family: Museosans-300;
        font-size: 12px;
    }

    a {
        background: $blue;
        text-decoration: none;
        color: #fff;
        padding: 4px 10px;
        border-radius: 5px;
    }
}