@import 'colors';
.login {
    width:100%;
    max-width: 360px;
    margin:  0 auto;
    padding: 30px 0;
    color:#222;
    font-family: MuseoSans-500,Sans serif;
    h2 {
        text-align: center;
        font-family: inherit;
        color:$blue;
    }

    p {
        text-align: center;
        margin:6px 0;
    }

    label {
        color:$blue;
    }
    .strong {
        font-weight: 600;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
    .btns {
        margin-bottom: 30px;
        button {
            width:100%;
        }
        p{
            color:#929292;
            font-size: 14px;
            margin:12px 0 24px 0;
        }
    }
    .blue {
        color:$blue;
    }
    .google-btn {
        margin-top: 12px;
        background: #ea4335;
        color:#fff;
        border:0;
        border-radius: 5px;
        padding:5px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            margin-right: 10px;
        }
    }
}