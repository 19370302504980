@import 'style/colors';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "dark": $dark,
    "info": $blue,
    "danger": $danger,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

@import './style/typo';