@import 'colors';
.buy-type {
    .card {
        margin:12px 0;
        overflow: hidden;
        input {
            width:0;
            height:0;
            overflow:hidden;
            visibility: hidden;
            display: none;
            
        }
        label {
            padding:12px 12px 12px 24px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            h3 {
                font-family: MuseoSans-700;
                font-size: 18px;
            }
            button {
                border:0;
                font-family: MuseoSans-500;
                border-radius: 5px;
                color: #fff;
                background-color:#116bf1;
                border:1px solid #095ad3;
                margin-top: 12px;
                &.available {
                    background-color:$primary;
                    border:1px solid $primary;
                }
                &.not-available {
                    background-color:$danger;
                    border:1px solid $danger;
                }
            }
            p{
                margin:0;
                font-family: MuseoSans-300;
                font-size: 14px;
            }
            .select {
                display:none;
                position:absolute;
                background-color:#089131;
                color:#fff;
                top:-30px;
                left:-30px;
                width:60px;
                height:60px;
                transform: rotate(45deg);
                align-items: center;
                justify-content: end;
                svg {
                    transform: rotate(-45deg);
                    margin:3px;
                }
            }
        }
        input:checked + label {
            background-color:#ffffff;
            .select {
                display:flex;
            }
        }
    }
}
