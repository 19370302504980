@import 'style/colors';

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Futura';
}

* {
    outline: none !important;
}

.btn {
    &.theme-1 {
        border: 2px solid $blue;
        background-color: $blue;
        color: white;
        font-family: Futura-Medium;
    }
}

.btn-primary {
    color: white !important;

}

.autocomplete-dropdown-container {
    color: #000;
}

.single-line {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
    width: 100%;
    overflow: hidden
}


.product-image {}

.price {
    font-size: 16px;
    font-weight: 700;
    /* font-family: 'Museosans-700'  */

    .off {
        opacity: 0.4;
        margin-left: 10px;
        text-decoration: line-through;

    }
}

body {
    .accordion-button:focus {
        z-index: 3;
        border-color: inherit;
        outline: 0;
        box-shadow: none;
    }
}

@media (max-width: 576px) {
    .d-xs-none {
        display: none !important;
    }

    .d-xs-block {
        display: block !important;
    }
}

@media (min-width: 577px) {
    .slick-dots {
        display: none !important;
    }
}



.dropdown-toggle::after {
    vertical-align: middle !important;
}

*:focus {
    box-shadow: none !important;
}

.MuiRating-label {
    top: 2px;
}

.add-review-modal {
    .note {
        display: flex;
        align-items: center;
        font-size: 14px;
        background: #eeeec6;
        padding: 2px 10px;
        border-radius: 3px;

        svg {
            width: 24px;
            height: 24px;
            margin-right: 12px;
            color: #d4a406
        }
    }

    .form {
        padding-bottom: 20px;
        max-width: 360px;
        margin: 0 auto;

        .field {
            margin-bottom: 12px;

            &.rating {
                margin-bottom: 0px;
                display: flex;
                align-items: center;

                >label {
                    min-width: 100px;
                    display: block
                }
            }
        }
    }

    .images {
        background: rgb(243, 243, 243);
        margin: 12px 0;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;

        .up-image {
            margin: 10px;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            background: #fff;

            img {
                width: 80px;
                height: 80px;
            }

            svg {
                position: absolute;
                right: 2px;
                top: 2px;
                width: 24px;
                height: 24px;
                background: rgba(255, 255, 255, 0.7);
                cursor: pointer;
                padding: 2px;
                border-radius: 3px;
                color: $danger;
            }
        }
    }

    .imageUpload {
        position: relative;

        input {
            position: absolute;
            z-index: 10;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
}

.send-message-modal {
    .msg {
        display: flex;
        align-items: center;

        .vendor {
            margin: 0;

            img {
                width: 120px;
                height: 120px;
                object-fit: cover;
            }

            .name {
                font-size: 16px;
                font-family: Futura-Medium;
                text-align: center;
                margin: 5px 0
            }
        }

        .form-group {
            margin-bottom: 34px;
            width: 100%;
            padding: 0 0 0 12px;

            textarea {
                height: 120px;
            }
        }

    }

    .selected-images {
        display: flex;
        flex-wrap: wrap;
        margin: 12px 0;

        .image {
            margin: 6px;
            border: 1px solid #ccc;
            border-radius: 5px;
            display: block;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            position: relative;
            overflow: hidden;

            svg {
                cursor: pointer;
                width: 24px;
                height: 24px;
                color: #d4d4d4;
                position: absolute;
                top: 0;
                right: 0;

                &:hover {
                    color: $danger;
                }
            }

            img {
                height: 100px;
            }
        }
    }

    input {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        margin-left: -16px;
        opacity: 0;
        cursor: pointer;
    }

}

.read-more-btn {
    color: $blue;
    font-weight: 700;
    cursor: pointer;
}

.add-inspo {
    >p {
        text-align: center;
    }

    .board {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 380px;
        margin: 0 auto 12px auto;

        p {
            margin: 0;
        }
    }

    form {
        max-width: 380px;
        margin: 0 auto 12px auto;
    }


}

input,
textarea {
    &:focus {
        border-color: #ced4da !important;
    }
}

.react-confirm-alert {
    font-family: MuseoSans-500;
    font-size: 16px;

    h1 {
        font-family: MuseoSans-700;
        font-size: 20px;
        color: #222;
    }
}

.react-confirm-alert-overlay {
    z-index: 999999998 !important;
}

.shop-now-btn {
    background: #014a89;
    color: #fff;
    display: inline-block;
    padding: 10px 25px;
    font-size: 18px;
    border-radius: 5px;
    font-family: Futura-Medium;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 20px;
    transition: all .5s ease-in-out;

    &:hover {
        background: #f9ece7;
        color: #000;
        box-shadow: 0 0 0 2px #f9ece7 inset
    }
}

.mr {
    margin-right: 12px;
}

.email-subs {
    display: flex;
    flex-wrap: wrap;

    input {
        border-radius: 5px 0 0 5px;
        width: 10px;
        min-width: calc(100% - 78px);
        max-width: 270px;
    }

    button {
        background: $blue;
        border: 0;
        padding: 0 15px;
        border-radius: 0 5px 5px 0;
        color: #fff;
    }

    &.dark {
        button {
            background: $dark;

        }
    }
}

.MuiDrawer-modal {
    z-index: 999 !important;
}

.banner {


    img {}
}

.banner {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    margin-bottom: 12px;
    height: 300px;

    img {
        position: absolute;
        display: block;
        margin: 0 auto;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}