@import 'colors';

.item {
    color: #555;

    .thumb {
        display: block;
        position: relative;

        img {
            width: 100%;
            padding-top: 100%;
            height: 0 !important;
            overflow: hidden;
            background: no-repeat 50% 50%;
            background-size: contain;
        }

        .loved {
            position: absolute;
            bottom: 10px;
            right: 10px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background: #ffffff;
            display: flex;
            cursor: pointer;

            svg {
                pointer-events: none;
                width: 30px;
                height: 30px;
                padding: 7px 6px 5px 6px;
                color: $primary;
            }
        }

        .inspo {
            position: absolute;
            bottom: 50px;
            right: 10px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background: #ffffff;
            display: flex;
            cursor: pointer;

            svg {
                pointer-events: none;
                width: 30px;
                height: 30px;
                padding: 3px;
                color: $blue;
            }
        }
    }

    h3 {
        text-align: center;
        margin: 10px 8px 10px 8px;
        font-size: 18px;
        font-family: 'Futura-Book';
        font-weight: 400;
        letter-spacing: 0.4px;
    }

    .rating {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 12px 0;
    }

    .rating-blank {
        height: 18px;
    }

    .price {
        font-family: 'MuseoSans-700';
        text-align: center;
        font-size: 18px;
        color: #555;
        margin-bottom: 8px;
    }

    .from {
        text-align: center;
        font-family: Futura-Medium;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 0;
        display: block;
        text-decoration: none;
        color: inherit
    }

    .addtocart {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 15px;
    }

    section.listing .item {
        background: #f4f4f4;
        margin-bottom: 15px;
        padding-bottom: 5px;
    }


}