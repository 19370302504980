@import 'colors';

.imaged {
    width: 100vh;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}
.gallery-right-arrow{
    position: absolute;
    right: 30%;
    // right: 0;
    top: 48%;
    font-size: 43px;
    color:#ec4981;
}
.gallery-left-arrow{
    top: 48%;
    font-size: 43px;
    position: absolute;
    color:#ec4981;
}

.search-modal .modal-content {
    background-color: rgba(0, 0, 0, .0001) !important;
    border: none;
}

.ReactGridGallery {
    margin-bottom: 20px;
}
#gallery{
    scroll-margin-top: 100px;
}
#listing{
    scroll-margin-top: 100px;
}
#faq{
    scroll-margin-top: 100px;
}
#friendors{
    scroll-margin-top: 100px;
}