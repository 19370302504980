@import 'colors';

.about-widget {
    font-size: 20px;
    background-color: lighten($accent, 14%);
    padding: 12px 0;

    h2 {
        text-align: center;
        font-family: Futura-Medium;
        color: #454f50;
        margin: 0 0 15px;
        font-size: 32px !important;

        @media (max-width: 991px) {
            font-size: 26px !important;
        }
    }
}

.container {
    max-width: 1270px;
}

.top-ban {
    text-decoration: none !important;
    color: $blue;
}

.blog-newsletter-widget {

    padding: 12px 0;

    .block {
        color: #454f50;

        a {
            text-decoration: none !important;
            color: $blue;
        }

        h2 {
            text-align: center;
            font-family: Futura-Medium;
            color: #454f50;
            margin: 0 0 15px;
            font-size: 32px !important;

            @media (max-width: 991px) {
                font-size: 26px !important;
            }
        }

        .image {
            width: 100%;
            height: 0;
            padding-top: 60%;
            margin: 15px 0;
            overflow: hidden;
            position: relative;
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            /*  border:1px solid #ffffff; */
            /* border-radius: 3px; */
            /* box-shadow: 0 0 5px rgba(0,0,0,0.2); */
        }

        p {
            font-size: 18px;
            font-family: 'Futura-Medium';
            text-align: center;
            text-decoration: none;
        }
    }
}

.testimonials-widget {
    padding: 12px 0;
    overflow-x: hidden;

    h2 {
        text-align: center;
        font-family: Futura-Medium;
        color: #454f50;
        margin: 0 0 15px;
        font-size: 32px !important;

        @media (max-width: 991px) {
            font-size: 26px !important;
        }
    }

    .slide {
        padding: 12px 0px;
        color: #2a2a2a;
        min-height: 200px;

        .testi-text {
            font-size: 18px;
            font-style: italic;
            text-align: center;
        }

        .author {
            font-size: 22px;
            font-family: Futura-Medium;
            text-align: center;
            padding-top: 16px;
        }
    }
}

.clients-widget {
    padding: 12px 0;
    overflow-x: hidden;

    h2 {
        text-align: center;
        font-family: Futura-Medium;
        color: #454f50;
        margin: 0 0 15px;
        font-size: 32px !important;

        @media (max-width: 991px) {
            font-size: 26px !important;
        }

    }

    .slick-track {
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .client-logo-slide {
        padding: 0;
        background: white;
        color: #2a2a2a;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 70%;
            display: block;
            margin: 0 auto;
        }
    }
}

.team-widget {
    padding: 12px 0;

    h2 {
        text-align: center;
        font-family: Futura-Medium;
        color: #454f50;
        margin: 0 0 15px;
        font-size: 32px !important;

        @media (max-width: 991px) {
            font-size: 26px !important;
        }
    }

    .team-slide {
        div.img {
            width: 70%;
            padding-top: 70%;
            background-size: cover;
            background-repeat: no-repeat;
            margin: 0 auto 15px;
            overflow: hidden;
            border-radius: 50%;
            display: block;
        }

        img {
            margin: 0 auto 15px;
            overflow: hidden;
            border-radius: 50%;
            display: block;
            width: 70%;
            object-fit: cover;
        }

        h2 {
            color: #353535;
            font-family: Futura-Medium;
            font-size: 18px !important;
            line-height: normal;
            margin: 0 0 5px;
            text-align: center;
        }

        h3 {
            color: #014a89;
            font-family: Futura-Medium;
            font-size: 18px !important;
            ;
            line-height: normal;
            margin: 0 0 5px;
            text-align: center;
        }
    }

    /* .slick-track {
        display:flex;
        align-items: center;
        overflow: hidden;
    }
    .client-logo-slide {
        padding: 0;
        background:white;
        color:#2a2a2a;
        display:flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%;
            display:block;
        }
    } */
}


.slider-arrows.slick-slider {
    .slick-prev {
        left: 15px;
        color: rgba(62, 72, 80, 0.7) !important;
        z-index: 10;
        width: 64px;
        height: 64px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 5px;
    }

    .slick-next {
        right: 15px;
        color: rgba(62, 72, 80, 0.7) !important;
        z-index: 10;
        width: 64px;
        height: 64px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 5px;
    }
}

.widget {
    padding: 12px 0;

    h2 {
        text-align: center;
        font-family: Futura-Medium;
        color: #454f50;
        margin: 0 0 30px;
        font-size: 32px !important;

        @media (max-width: 991px) {
            font-size: 26px !important;
        }
    }

    .thumb-block {
        color: #454f50;
        font-family: 'Futura-Medium';
        text-align: center;
        width: 100%;
        display: block;
        text-decoration: none;

        .zoom-img {
            overflow: hidden;
            position: relative;
            padding-top: 100%;

            /* border-radius:3px; */
            img {
                transition: all .5s ease-in-out;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }

        .title-1 {
            font-size: 20px;
            margin: 5px 0 0 0;
            line-height: 28px;
        }

        .title-2 {
            font-size: 16px;
            color: #454f50;
            font-family: 'MuseoSans-700';
            line-height: normal;
            margin-top: 10px;

            >svg {}
        }
    }
}

.searchWidgetDiv {
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    padding-left: 20px;
    padding-top: 15px;
    box-shadow: 5px 5px 15px #e0e0e0;
    -webkit-box-shadow: 5px 5px 15px #e0e0e0;
    -moz-box-shadow: 5px 5px 15px #e0e0e0;

    .searchBtn .btn {
        border: 1px solid #EC4981;
        padding: 20px;
        border-radius: 50px;
        background-color: #EC4981;
        color: #fff;
        margin-right: 15px;

        .searchbt {
            font-size: 20px;
        }
    }
}

.searchWidgetDivMobile {
    .searchBtn {
        display: flex;
        justify-content: center;

        .btn {
            border: 1px solid #EC4981;
            padding: 8px 40px;
            background-color: #EC4981;
            color: #fff;
            margin-right: 15px;

            .searchbt {
                font-size: 17px;
            }
        }
    }
}

.homepagesearch {
    display: flex;
    justify-content: center;

    .btn {
        background: rgb(236, 73, 129);
        background: linear-gradient(90deg, rgba(236, 73, 129, 1) 51%, rgba(255, 0, 99, 0.8603816526610644) 100%);

        .searchbt {
            margin-right: 5px;
            font-size: 18px;
        }
    }
}

@media (min-width: 992px) {
    .my-modal-lg {
        width: auto;
    }
}

@media (min-width: 768px) {
    .my-modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
}

@media (min-width: 768px) {
    .my-modal-content {
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        height: auto;
        min-height: 100%;
        border-radius: 0;
    }
}