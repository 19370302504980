@import 'colors';
h1,h2,h3,h4,h5 {
    font-family: 'Futura-Medium';
    /* font-family: Galada,cursive; */
    font-weight: 100;
}
h3 {
    font-size: 22px;
}
h4 {
    font-size: 20px;
}

.announcement {
    font-size: 38px !important;
    text-align: center;
    color: $primary;
    margin: 25px 0;
}