@import 'colors';
.checkout {
    .cart {
        margin: 24px 0;
        background: #f7f9f8;
        padding-bottom: 16px;
        .cart-items {
            height: auto;
            position: relative;
            min-height: 260px;
            .cart-item {
           
                border-bottom: 1px solid #dbdbdb;
              
            }
        }
    }
    .bill {
        .details-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.payment {
    
    .form {
        margin: 24px auto;
        max-width: 600px;
        .form-control{
            line-height: 1.3rem;
            height:48px;
            font-size: 18px;
        }
        
    }

    
}
